body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans TC', sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.logo {
  display: flex;
  justify-content: center;
  margin: 10px auto;
  height: 60px;
  align-items: center;
}

.ant-layout-sider {
  background: white !important;
}

.ant-layout-sider-zero-width-trigger {
  top: 0 !important;
  border-radius: 0 0 4px 0 !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none !important;
}

.social-apps {
  cursor: pointer;
  text-decoration: none;
  padding: 6px;
}

.svg-inline {
  width: 2rem;
}

.ant-card-meta-title {
  color: #777 !important;
}

.ant-card-meta-description {
  height: 110px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-card-body {
  background: white;
  height: 170px;
  padding: 16px !important;
}

.thumb.selected {
  border: 1px solid #d1cfe2 !important;
}

.nav-text {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ant-menu-item {
  a {
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
  }
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// .ant-layout-sider-children {
//   background: pink;
// }

// .ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
//   background: pink !important;
// }

.gmap-controls {
  display: block;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid transparent;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 300;
  height: 34px;
  margin-left: 17px;
  margin-top: 10px;
  outline: none;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 300px;
}

.gmap-controls:focus {
  border-color: #4d90fe;
}

.blinking-button {
  background-color: #004a7f;
  border-radius: 2px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;

  font-size: 20px;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  animation: glowing 1500ms infinite;
}

@keyframes glowing {
  0% {
    background-color: #70a5ee;
    box-shadow: 0 0 3px #70a5ee;
  }
  50% {
    background-color: #3c7bf6;
    box-shadow: 0 0 40px #3c7bf6;
  }
  100% {
    background-color: #70a5ee;
    box-shadow: 0 0 3px #70a5ee;
  }
}

.ant-descriptions-item-label,
.ant-descriptions-item-content {
  font-size: 1rem !important;
}

.ant-typography ul li,
.ant-typography ol li {
  margin: 0 0 0 1px !important;
  padding: 0 0 0 1px !important;
}

.ellipsis-overflowing-child {
  margin-bottom: 206px;
  padding: 0 !important;
  position: relative;
}

.ellipsis-overflowing-child .ellipsis-helper {
  width: 8em;
  background: -moz-linear-gradient(
    left,
    rgba(221, 221, 221, 0) 0%,
    rgba(221, 221, 221, 1) 100%
  ); /* FF3.6+ */
  background: -o-linear-gradient(
    left,
    rgba(221, 221, 221, 0) 0%,
    rgba(221, 221, 221, 1) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    left,
    rgba(221, 221, 221, 0) 0%,
    rgba(221, 221, 221, 1) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to right,
    rgba(221, 221, 221, 0) 0%,
    rgba(221, 221, 221, 1) 100%
  ); /* W3C */
  background: red;
}

.ellipsis-overflowing-child .ellipsis-helper:after {
  content: '...';
  float: right;
  padding-right: 0.4em;
}

.ellipsis-container {
  height: 150px;
  position: relative;
  font-size: 0.8rem;
  line-height: 1.2rem;
  overflow: hidden;
  column-count: 1;
}

.smoobu-booking-tool-container {
  height: 100vh !important;
}

.MuiInputBase-input {
  font-size: 0.8rem !important;
  color: rgba(0, 0, 0, 0.65) !important;
}

// 不讓刪除檔案
:global(.anticon-delete){
  display:none;
}